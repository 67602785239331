function O(e) {
  if (e.startsWith("http")) {
    const { protocol: t, host: s } = new URL(e);
    return s.endsWith("hf.space") ? {
      ws_protocol: "wss",
      host: s,
      http_protocol: t
    } : {
      ws_protocol: t === "https:" ? "wss" : "ws",
      http_protocol: t,
      host: s
    };
  }
  return {
    ws_protocol: "wss",
    http_protocol: "https:",
    host: e
  };
}
const T = /^[^\/]*\/[^\/]*$/, A = /.*hf\.space\/{0,1}$/;
async function M(e) {
  const t = e.trim();
  if (T.test(t)) {
    const s = (await (await fetch(`https://huggingface.co/api/spaces/${t}/host`)).json()).host;
    return {
      space_id: e,
      ...O(s)
    };
  }
  if (A.test(t)) {
    const { ws_protocol: s, http_protocol: n, host: o } = O(t);
    return {
      space_id: o.replace(".hf.space", ""),
      ws_protocol: s,
      http_protocol: n,
      host: o
    };
  }
  return {
    space_id: !1,
    ...O(t)
  };
}
function v(e) {
  let t = {};
  return e.forEach(({ api_name: s }, n) => {
    s && (t[s] = n);
  }), t;
}
const F = /^(?=[^]*\b[dD]iscussions{0,1}\b)(?=[^]*\b[dD]isabled\b)[^]*$/;
async function B(e) {
  try {
    const s = (await fetch(
      `https://huggingface.co/api/spaces/${e}/discussions`,
      {
        method: "HEAD"
      }
    )).headers.get("x-error-message");
    return !(s && F.test(s));
  } catch {
    return !1;
  }
}
const J = "This application is too busy. Keep trying!", D = "Connection errored out.";
async function z(e, t) {
  try {
    var s = await fetch(e, {
      method: "POST",
      body: JSON.stringify(t),
      headers: { "Content-Type": "application/json" }
    });
  } catch {
    return [{ error: D }, 500];
  }
  return [await s.json(), s.status];
}
async function Q(e, t) {
  const s = new FormData();
  t.forEach((f) => {
    s.append("files", f);
  });
  try {
    var n = await fetch(`${e}/upload`, {
      method: "POST",
      body: s
    });
  } catch {
    return { error: D };
  }
  return { files: await n.json() };
}
async function V(e, t) {
  return new Promise(async (s) => {
    const n = {
      predict: R,
      on: W,
      off: j,
      cancel: G
    };
    if (typeof window > "u" || !("WebSocket" in window) || window.WebSocket.CLOSING !== 2) {
      const r = await import("./wrapper-17f133d7.js");
      global.WebSocket = r.WebSocket;
    }
    const o = {}, { ws_protocol: f, http_protocol: h, host: w, space_id: y } = await M(e), N = Math.random().toString(36).substring(2), C = /* @__PURE__ */ new Map(), P = {};
    let d, S = {};
    function I(r) {
      return d = r, S = v((r == null ? void 0 : r.dependencies) || []), {
        config: d,
        ...n
      };
    }
    function W(r, u) {
      const c = o;
      let a = c[r] || [];
      return c[r] = a, a == null || a.push(u), { ...n, config: d };
    }
    function j(r, u) {
      const c = o;
      let a = c[r] || [];
      return a = a == null ? void 0 : a.filter((q) => q !== u), c[r] = a, { ...n, config: d };
    }
    function G(r, u) {
      var a;
      const c = typeof u == "number" ? u : S[r];
      p({
        type: "status",
        endpoint: r,
        fn_index: c,
        status: "complete",
        queue: !1
      }), (a = C.get(c)) == null || a.close();
    }
    function p(r) {
      let c = o[r.type] || [];
      c == null || c.forEach((a) => a(r));
    }
    async function L(r) {
      if (t && t(r), r.status === "running")
        try {
          d = await U(`${h}//${w}`), s(I(d));
        } catch {
          t && t({
            status: "error",
            message: "Could not load this space.",
            load_status: "error",
            detail: "NOT_FOUND"
          });
        }
    }
    try {
      d = await U(`${h}//${w}`), s(I(d));
    } catch {
      y ? $(
        y,
        T.test(y) ? "space_name" : "subdomain",
        L
      ) : t && t({
        status: "error",
        message: "Could not load this space.",
        load_status: "error",
        detail: "NOT_FOUND"
      });
    }
    function x(r, u) {
      return new Promise((c, a) => {
        const q = r.replace(/^\//, "");
        let i = typeof u.fn_index == "number" ? u.fn_index : S[q];
        if (K(i, d))
          p({
            type: "status",
            endpoint: r,
            status: "pending",
            queue: !1,
            fn_index: i
          }), z(
            `${h}//${w + d.path}/run${r.startsWith("/") ? r : `/${r}`}`,
            {
              ...u,
              session_hash: N
            }
          ).then(([_, g]) => {
            g == 200 ? (p({
              type: "status",
              endpoint: r,
              fn_index: i,
              status: "complete",
              eta: _.average_duration,
              queue: !1
            }), p({
              type: "data",
              endpoint: r,
              fn_index: i,
              data: _.data
            })) : p({
              type: "status",
              status: "error",
              endpoint: r,
              fn_index: i,
              message: _.error,
              queue: !1
            });
          }).catch((_) => {
            throw p({
              type: "status",
              status: "error",
              message: _.message,
              endpoint: r,
              fn_index: i,
              queue: !1
            }), new Error(_.message);
          });
        else {
          p({
            type: "status",
            status: "pending",
            queue: !0,
            endpoint: r,
            fn_index: i
          });
          const _ = `${f}://${w + d.path}/queue/join`, g = new WebSocket(_);
          C.set(i, g), g.onclose = (E) => {
            E.wasClean || p({
              type: "status",
              status: "error",
              message: D,
              queue: !0,
              endpoint: r,
              fn_index: i
            });
          }, g.onmessage = function(E) {
            const k = JSON.parse(E.data), { type: m, status: l, data: b } = H(
              k,
              P[i]
            );
            if (m === "update" && l)
              p({ type: "status", endpoint: r, fn_index: i, ...l }), l.status === "error" && (g.close(), a(l));
            else if (m === "hash") {
              g.send(JSON.stringify({ fn_index: i, session_hash: N }));
              return;
            } else
              m === "data" ? g.send(JSON.stringify({ ...u, session_hash: N })) : m === "complete" ? (p({
                type: "status",
                ...l,
                status: l == null ? void 0 : l.status,
                queue: !0,
                endpoint: r,
                fn_index: i
              }), g.close()) : m === "generating" && p({
                type: "status",
                ...l,
                status: l == null ? void 0 : l.status,
                queue: !0,
                endpoint: r,
                fn_index: i
              });
            b && (p({
              type: "data",
              data: b.data,
              endpoint: r,
              fn_index: i
            }), c({ data: b.data }));
          };
        }
      });
    }
    function R(r, u) {
      return x(r, u);
    }
  });
}
function K(e, t) {
  var s, n, o, f;
  return !(((n = (s = t == null ? void 0 : t.dependencies) == null ? void 0 : s[e]) == null ? void 0 : n.queue) === null ? t.enable_queue : (f = (o = t == null ? void 0 : t.dependencies) == null ? void 0 : o[e]) != null && f.queue) || !1;
}
async function U(e) {
  if (typeof window < "u" && window.gradio_config && location.origin !== "http://localhost:9876") {
    const t = window.gradio_config.root, s = window.gradio_config;
    return s.root = e + s.root, { ...s, path: t };
  } else if (e) {
    let t = await fetch(`${e}/config`);
    if (t.status === 200) {
      const s = await t.json();
      return s.path = s.path ?? "", s.root = e, s;
    } else
      throw new Error("Could not get config.");
  }
  throw new Error("No config or app endpoint found");
}
async function $(e, t, s) {
  let n = t === "subdomain" ? `https://huggingface.co/api/spaces/by-subdomain/${e}` : `https://huggingface.co/api/spaces/${e}`, o, f;
  try {
    if (o = await fetch(n), f = o.status, f !== 200)
      throw new Error();
    o = await o.json();
  } catch {
    s({
      status: "error",
      load_status: "error",
      message: "Could not get space status",
      detail: "NOT_FOUND"
    });
    return;
  }
  if (!o || f !== 200)
    return;
  const {
    runtime: { stage: h },
    id: w
  } = o;
  switch (h) {
    case "STOPPED":
    case "SLEEPING":
      s({
        status: "sleeping",
        load_status: "pending",
        message: "Space is asleep. Waking it up...",
        detail: h
      }), setTimeout(() => {
        $(e, t, s);
      }, 1e3);
      break;
    case "RUNNING":
    case "RUNNING_BUILDING":
      s({
        status: "running",
        load_status: "complete",
        message: "",
        detail: h
      });
      break;
    case "BUILDING":
      s({
        status: "building",
        load_status: "pending",
        message: "Space is building...",
        detail: h
      }), setTimeout(() => {
        $(e, t, s);
      }, 1e3);
      break;
    default:
      s({
        status: "space_error",
        load_status: "error",
        message: "This space is experiencing an issue.",
        detail: h,
        discussions_enabled: await B(w)
      });
      break;
  }
}
function H(e, t) {
  switch (e.msg) {
    case "send_data":
      return { type: "data" };
    case "send_hash":
      return { type: "hash" };
    case "queue_full":
      return {
        type: "update",
        status: {
          queue: !0,
          message: J,
          status: "error"
        }
      };
    case "estimation":
      return {
        type: "update",
        status: {
          queue: !0,
          status: t || "pending",
          size: e.queue_size,
          position: e.rank,
          eta: e.rank_eta
        }
      };
    case "progress":
      return {
        type: "update",
        status: {
          queue: !0,
          status: "pending",
          progress: e.progress_data
        }
      };
    case "process_generating":
      return {
        type: "generating",
        status: {
          queue: !0,
          message: e.success ? null : e.output.error,
          status: e.success ? "generating" : "error",
          progress: e.progress_data,
          eta: e.average_duration
        },
        data: e.success ? e.output : null
      };
    case "process_completed":
      return {
        type: "complete",
        status: {
          queue: !0,
          message: e.success ? void 0 : e.output.error,
          status: e.success ? "complete" : "error",
          progress: e.progress_data,
          eta: e.output.average_duration
        },
        data: e.success ? e.output : null
      };
    case "process_starts":
      return {
        type: "update",
        status: {
          queue: !0,
          status: "pending",
          size: e.rank,
          position: 0
        }
      };
  }
  return { type: "none", status: { status: "error", queue: !0 } };
}
export {
  V as client,
  z as post_data,
  Q as upload_files
};
